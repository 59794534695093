<template lang="pug">
    #why-need-license-photo(v-esc-close="cancel")
      .modal-text
        icon(data="@icon/info.svg" color="#3f3f3f")
        p We use your ID photos and selfie photo to verify your identity. This information is private and will only be shared with Kit and your insurer.
      .modal-footer
        button.button(@click="cancel") Got it!
  </template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {
    cancel,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */

function cancel() {
  this.$modal.close('WhyNeedLicensePhoto')
}
</script>
